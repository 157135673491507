const baseFitmentFields = ['Year', 'Make', 'Model', 'Drive', 'Engine'];
const extraFitmentFields = [];

globalThis.Convermax.quickview = (item) => (e) => {
  e.preventDefault();
  window.quickview(`/product.asp?lt_c=1&itemid=${item.CatalogID}&qv=1`);
};

globalThis.Convermax.fitmentFieldsToStub = (fields) =>
  fields.length && [...baseFitmentFields, ...extraFitmentFields].filter((f) => !fields.includes(f));

const priceLevel = globalThis.Convermax.cartpricelevel ? btoa(globalThis.Convermax.cartpricelevel) : '';
const customerId = globalThis.Convermax.cartuserid ? btoa(globalThis.Convermax.cartuserid) : '';

function getLocalPreselection(pageType, defaults) {
  const { termFromBreadcrumbs } = defaults;
  if (pageType === 'category' && termFromBreadcrumbs.startsWith('All Categories>')) {
    return [{ field: 'Category', term: termFromBreadcrumbs.replace('All Categories>', '') }];
  }

  return defaults.getter(pageType);
}

const onSearchBoxSubmit = () => {
  window.Convermax.discardVehicle?.();
};

export default {
  platform: 'shift4shop',
  searchPageUrl: '/search',
  getLocalPreselection,
  SearchRequestDefaults: {
    pageSize: 36,
    queryCorrectionLevel: 'Spelling,Wordbreaking',
    extra: {
      // eslint-disable-next-line camelcase
      plv_id: priceLevel,
      // eslint-disable-next-line camelcase
      customer_id: customerId,
    },
  },
  autocomplete: {
    queryCorrectionLevel: 'Spelling,Wordbreaking',
    requestDefaults: {
      extra: {
        // eslint-disable-next-line camelcase
        plv_id: priceLevel,
        // eslint-disable-next-line camelcase
        customer_id: customerId,
      },
    },
  },
  page: {
    getPageType: (defaults) => (window.location.pathname === '/products.html' ? 'parts' : defaults.getter()),
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    onVehicleSelected: () => {
      window.Convermax.clearCurrentQuery?.();
    },
    isAutoVehicleSelectionDisabled: true,
    doNotSaveSelectedVehicle: true,
    isVehicleSelectionIsolated: true,
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'BrandPage',
      type: 'SearchPage',
      location: 'body.search-groups .content-area',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.category-page .category-products',
      template: 'SearchPage',
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'Price': 'Price: Low to High',
        'Price:desc': 'Price: High to Low',
        'Name': 'Name',
        'DateCreated:desc': 'Newest',
      },
    },
    {
      name: 'SearchResult',
    },
    {
      name: 'FacetPanel',
      disableCollapse: true,
    },
    {
      name: 'SearchBoxDialogButton',
      location: {
        replace: '.site-header #searchBox',
        class: 'searchBox',
      },
      template: 'searchBox/dialogButtonAdaptable',
      onSubmit: onSearchBoxSubmit,
    },
    {
      name: 'HeaderVehicleWidget',
      type: 'VehicleWidget',
      location: {
        firstChildOf: '.ymm-bar-wrapper .container',
        class: 'cm_vehicle-widget__header_desktop',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      columnBreakpoint: 767,
    },
    {
      name: 'HeaderVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        lastChildOf: '#sticky-header',
        class: 'cm_vehicle-widget__header_mobile container',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      visibleIf: () => window.innerWidth < 768,
      initCollapsed: true,
      columnBreakpoint: 767,
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: { insertBefore: 'body.listing-page .addToCartBlock' },
      template: 'fitmentSearch/verifyFitment',
    },
    {
      name: 'FitmentTable',
      location: 'body.listing-page #rTabs .cm_fitment',
      template: 'fitmentSearch/fitmentTable',
    },
    {
      name: 'RelatedItems',
      location: { lastChildOf: 'body.listing-page form#add' },
      template: 'product/relatedItems',
      count: 3,
      // show related items by the selected vehicle
      getSelection: () => null,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
      onSubmit: onSearchBoxSubmit,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
  ],
};
