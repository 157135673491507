
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function SearchPageRT () {
    return _createElement('div', { 'className': 'clear-self cm_SearchPage' }, [this.FacetPanel(function () {
            return _createElement('div', { 'className': 'cm_FacetPanel' }, [[this.filterChips(function () {
                        function repeatChips1(chips, chipsIndex) {
                            return [chips(function () {
                                    function scopeInchMm1_rangeChip() {
                                        var inch = [].includes(this.field) ? '"' : '';
                                        var mm = [
                                            'wheel_bore',
                                            'wheel_offset'
                                        ].includes(this.field) ? 'mm' : '';
                                        return _createElement('div', { 'className': 'facetdiv cmTemplate_rangeChip' }, _createElement('a', {
                                            'className': 'facetentrykey',
                                            'tabIndex': '0',
                                            'role': 'listitem'
                                        }, _createElement('span', { 'className': 'cm_chip-name' }, this.name, ':'), this.finite ? _createElement(_Fragment, { 'key': '365' }, this.selectedRange[0], inch, mm, ' to ', this.selectedRange[1], inch, mm) : null, this.toPosInf ? _createElement(_Fragment, { 'key': '491' }, this.selectedRange[0], inch, mm, ' and more') : null, this.toNegInf ? _createElement(_Fragment, { 'key': '591' }, this.selectedRange[1], inch, mm, ' and less') : null), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                                'className': 'cm_icon cm_icon-times',
                                                'height': '20px',
                                                'role': 'img',
                                                'viewBox': '0 0 22 22',
                                                'key': '7210'
                                            }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))]));
                                    }
                                    return this.template === 'chip' ? _createElement('div', { 'className': 'facetdiv cmTemplate_chip' }, _createElement('a', {
                                        'className': 'facetentrykey',
                                        'tabIndex': '0',
                                        'role': 'listitem'
                                    }, _createElement('span', { 'className': 'cm_chip-name' }, this.name === 'filterQuery' ? 'search within' : this.name, ':'), '\n  ', this.value, '\n'), _createElement('div', { 'className': 'facetentryval' }, [_createElement('svg', {
                                            'className': 'cm_icon cm_icon-times',
                                            'height': '20px',
                                            'role': 'img',
                                            'viewBox': '0 0 22 22',
                                            'key': '2570'
                                        }, _createElement('path', { 'd': 'M1,1L21,21M21,1L1,21' }))])) : this.template === 'rangeChip' ? scopeInchMm1_rangeChip.apply(this, []) : _createElement('div', { 'className': 'cmTemplate_Unknow' }, JSON.stringify(this));
                                }, { count: undefined })];
                        }
                        return _createElement('div', { 'className': 'cm_filterChips' }, _createElement('div', { 'className': 'facetholder' }, _createElement('div', {
                            'className': 'facettitle',
                            'tabIndex': '0'
                        }, _createElement('span', {}, 'Current Search'), _createElement('div', {
                            'className': 'facetreset',
                            'data-cm-role': 'start-over'
                        }, 'Clear All')), _createElement('div', { 'className': 'facetbody' }, _createElement.apply(this, [
                            'div',
                            {
                                'role': 'list',
                                'className': 'cmRepeater_chips'
                            },
                            _map(this.chips, repeatChips1.bind(this))
                        ]))));
                    }, {
                        widgetName: 'filter-chips',
                        items: undefined
                    })]], [this.facets(function () {
                    function repeatFacets1(facets, facetsIndex) {
                        return [facets(function () {
                                function repeatShowAlwaysValues1(ShowAlwaysValues, ShowAlwaysValuesIndex) {
                                    return [ShowAlwaysValues(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues2(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), _createElement('a', {
                                                'className': 'facetentrykey',
                                                'tabIndex': '0',
                                                'role': 'listitem'
                                            }, this.value), _createElement('div', { 'className': 'facetentryval' }, this.hitCount));
                                        }, { count: undefined })];
                                }
                                function repeatValues3(Values, ValuesIndex) {
                                    return [Values(function () {
                                            return _createElement('div', { 'className': 'facetdiv ' + this.facetDivClass }, _createElement('input', {
                                                'type': 'checkbox',
                                                'className': 'cm_facet_checkbox',
                                                'readOnly': true,
                                                'checked': this.isSelected
                                            }), !this.isCustomRange ? [
                                                _createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '1541'
                                                }, _createElement('span', {}, this.value)),
                                                _createElement('div', {
                                                    'className': 'facetentryval',
                                                    'key': '1543'
                                                }, this.hitCount)
                                            ] : null, this.isCustomRange ? [_createElement('a', {
                                                    'className': 'facetentrykey',
                                                    'tabIndex': '0',
                                                    'role': 'listitem',
                                                    'key': '3901'
                                                }, this.finite ? _createElement('span', { 'key': '503' }, this.range[0], ' - ', this.range[1]) : null, this.toPosInf ? _createElement('span', { 'key': '580' }, this.range[0], ' and more') : null, this.toNegInf ? _createElement('span', { 'key': '650' }, this.range[1], ' and less') : null)] : null);
                                        }, { count: undefined })];
                                }
                                return _createElement('div', { 'className': 'facetholder cm_field-' + this.field }, this.template !== 'toggleFacet' ? _createElement('rt-virtaul', { 'key': '55' }, _createElement('div', {
                                    'className': 'facettitle',
                                    'data-cm-role': 'toggle-facet',
                                    'tabIndex': '0'
                                }, _createElement('span', {}, this.name), this.selectedSize > 0 ? _createElement('div', {
                                    'className': 'facetreset',
                                    'data-cm-role': 'reset-facet',
                                    'key': '231'
                                }, 'Clear') : null), _createElement('div', {
                                    'className': 'facetbody' + (this.template === 'simpleFacet' && this.needShowMore && !this.moreValuesToShow ? ' expanded' : ''),
                                    'role': 'list'
                                }, this.template === 'simpleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_simpleFacet',
                                    'key': '535'
                                }, this.showFilterInput ? _createElement('div', {
                                    'className': 'filter-input',
                                    'key': '621'
                                }, [this.filterInput(function () {
                                        return _createElement('div', {
                                            'className': 'input  cm_filterInput',
                                            'placeholder': 'Enter'
                                        });
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })], this.inputNotEmpty ? _createElement('span', {
                                    'className': 'filter-input_clear-container',
                                    'onClick': this.clearInput,
                                    'key': '941'
                                }, _createElement('span', { 'className': 'filter-input_clear' }, '\u2715')) : null) : null, !this.ShowAlwaysValues.length && !this.Values.length ? [_createElement('div', {
                                        'className': 'facetdiv',
                                        'key': '11101'
                                    }, _createElement('span', { 'className': 'facetentrykey' }, 'No entries found'))] : null, _createElement('div', { 'className': 'facetvalues' + (this.showFilterInput && !this.moreValuesToShow ? ' overflowed' : '') }, _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_ShowAlwaysValues' },
                                    _map(this.ShowAlwaysValues, repeatShowAlwaysValues1.bind(this))
                                ]), _createElement.apply(this, [
                                    'div',
                                    { 'className': 'cmRepeater_Values' },
                                    _map(this.Values, repeatValues2.bind(this))
                                ]), this.needShowMore ? _createElement('div', {
                                    'className': 'facetdiv cm_show-all-container',
                                    'key': '2941'
                                }, _createElement('a', {
                                    'className': 'cm_show-all',
                                    'data-cm-role': 'toggle-show-more',
                                    'tabIndex': '0'
                                }, this.moreValuesToShow ? ['Show more'] : null, !this.moreValuesToShow ? ['Show less'] : null)) : null)) : null, this.template === 'priceFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_priceFacet',
                                    'key': '3269'
                                }, !this.ranges.length ? [
                                    _createElement('div', { 'key': '33531' }, this.selectedRawRange[0], ' to ', this.selectedRawRange[1]),
                                    '\n  ',
                                    this.slider,
                                    '\n  ',
                                    _createElement('div', {
                                        'className': 'cm_flex',
                                        'key': '33533'
                                    }, _createElement('div', { 'className': 'cm_flex-grow' }, 'Min: ', this.rawMin), _createElement('div', {}, 'Max: ', this.rawMax))
                                ] : null, this.ranges.length ? [_createElement.apply(this, [
                                        'div',
                                        {
                                            'className': 'cmRepeater_Values',
                                            'key': '36171'
                                        },
                                        _map(this.Values, repeatValues3.bind(this))
                                    ])] : null, [this.Inputs(function () {
                                        return _createElement('div', { 'className': 'input cm_Inputs' }, [this.inputMin(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Min price',
                                                    'className': 'cm_inputMin'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('span', { 'className': 'separator' }, '\u2014'), [this.inputMax(function () {
                                                return _createElement('div', {
                                                    'placeholder': 'Max price',
                                                    'className': 'cm_inputMax'
                                                });
                                            }, {
                                                widgetName: 'undefined',
                                                items: undefined
                                            })], _createElement('button', {
                                            'type': 'button',
                                            'className': 'button btn btn-default',
                                            'onClick': this.setCustomRange
                                        }, '\n    GO\n  '));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null, this.template === 'selectFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_selectFacet',
                                    'key': '6197'
                                }, [this.select(function () {
                                        function repeatEntry1(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry2(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry3(entry, entryIndex) {
                                            return _createElement('option', {
                                                'disabled': this.loading,
                                                'value': entry.term,
                                                'key': entry.value
                                            }, '\n            ', entry.value, this.showHitCount ? ` (${ entry.hitCount })` : '', '\n          ');
                                        }
                                        function repeatEntry4(entry, index) {
                                            return _createElement('div', {
                                                'className': 'option' + (this.isSearchable && this.activeIndex === index || !this.isSearchable && entry.selected ? ' cm_checked' : ''),
                                                'key': entry.value,
                                                'onClick': () => this.onChange(entry.term)
                                            }, this.showCheckboxes ? _createElement('input', {
                                                'type': 'checkbox',
                                                'readOnly': true,
                                                'checked': entry.selected,
                                                'className': 'cm_dropdown_checkbox cm_facet_checkbox',
                                                'key': '3596'
                                            }) : null, _createElement('div', { 'className': 'cm_dropdown_value' }, entry.value), this.showHitCount ? _createElement('div', {
                                                'className': 'cm_dropdown_hitCount',
                                                'key': '3798'
                                            }, entry.hitCount) : null);
                                        }
                                        return _createElement('div', { 'className': 'cm_select' }, _createElement('div', { 'className': this.extraClassName }, !this.isSearchable ? _createElement('div', {
                                            'className': 'cm_select_toggle' + (this.useNativeDropdown ? ' cm_select_toggle__native' : '') + ' cm_toggle-dropdown',
                                            'key': '62'
                                        }, _createElement('select', {
                                            'className': 'cm_select_inner-select cm_select__pretty',
                                            'onChange': this.onChange,
                                            'value': this.useNativeDropdown ? this.firstSelectedTerm || '' : '_current',
                                            'disabled': this.disabled,
                                            'aria-label': this.title
                                        }, this.useNativeDropdown ? [
                                            !(this.hideNullOption && this.entries.some(e => e.selected)) ? _createElement('option', {
                                                'value': '',
                                                'key': 'null-option'
                                            }, '\n          ', '', this.title, '\n        ') : null,
                                            this.loading ? _createElement('option', {
                                                'key': 'loading-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            this.title === 'Make' && (this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '8031'
                                                }, 'Popular Makes'),
                                                _map(this.entries.filter(entry => entry.payload === 'Popular'), repeatEntry1.bind(this)),
                                                _createElement('option', {
                                                    'className': 'cm_option_title',
                                                    'disabled': true,
                                                    'key': '8034'
                                                }, 'All Makes'),
                                                _map(this.entries.filter(entry => entry.payload !== 'Popular'), repeatEntry2.bind(this))
                                            ] : null,
                                            this.title !== 'Make' || !(this.entries.some(entry => entry.payload === 'Popular') && this.entries.some(entry => entry.payload !== 'Popular')) ? [_map(this.entries, repeatEntry3.bind(this))] : null
                                        ] : null, !this.useNativeDropdown ? _createElement('option', {
                                            'key': '_current',
                                            'value': '_current'
                                        }, '\n        ', this.selectedEntries.length ? `${ this.selectedEntries.map(e => e.value).join(', ') }` : `${ '' } ${ this.title }`, '\n      ') : null)) : null, this.isSearchable ? [[this.filterInput(function () {
                                                    return _createElement('div', { 'className': 'cm_filterInput' });
                                                }, {
                                                    widgetName: 'undefined',
                                                    items: undefined
                                                })]] : null, !this.disabled && !this.useNativeDropdown ? _createElement.apply(this, [
                                            'div',
                                            {
                                                'className': 'cm_dropdown cm_select_dropdown cm_hide',
                                                'aria-label': this.title,
                                                'key': '2892'
                                            },
                                            !this.hideNullOption ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'onClick': () => this.onChange('')
                                            }, '\n      ', '', this.title, '\n    ') : null,
                                            this.loading ? _createElement('div', {
                                                'className': 'option',
                                                'key': 'null-option',
                                                'disabled': true
                                            }, '...loading...') : null,
                                            !this.loading ? _map(this.entries, repeatEntry4.bind(this)) : null
                                        ]) : null));
                                    }, {
                                        widgetName: 'undefined',
                                        items: undefined
                                    })]) : null)) : null, this.template === 'toggleFacet' ? _createElement('div', {
                                    'className': 'cmTemplate_toggleFacet',
                                    'key': '12206'
                                }, _createElement('div', { 'className': 'facettitle cm_facet-toggle' }, _createElement('span', {}, this.name), _createElement('label', { 'className': 'cm_facet-toggle_switch' }, _createElement('input', {
                                    'className': 'cm_facet-toggle_input',
                                    'type': 'checkbox',
                                    'checked': this.isToggled,
                                    'onClick': this.toggleFacet
                                }), _createElement('span', { 'className': 'cm_facet-toggle_slider' })))) : null);
                            }, { count: undefined })];
                    }
                    return _createElement('div', { 'className': 'cm_facets' }, _createElement.apply(this, [
                        'div',
                        { 'className': 'cmRepeater_facets' },
                        _map(this.facets, repeatFacets1.bind(this))
                    ]));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], _createElement('div', { 'className': 'cm_main-content' }, _createElement('div', { 'id': 'itemsBlock' }, [this.message(function () {
            return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                    'href': 'mailto:support@convermax.com',
                    'className': 'cm_message-link'
                }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                    'className': 'cm_message__incompatible',
                    'key': '4950'
                }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                    'className': 'cm_message__universal',
                    'key': '7690'
                }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'href': this.searchByVehicleUrl
                }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                'className': 'cmTemplate_badRequest',
                'key': '1136'
            }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.startOver
            }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                'className': 'cmTemplate_corrected',
                'key': '1554'
            }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                'className': 'cmTemplate_networkError',
                'key': '1816'
            }, 'Network error\n', this.error ? [
                '(id: ',
                this.error.userId,
                ', timestamp: ',
                this.error.timestamp,
                ', instance: ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                'className': 'cmTemplate_nothing',
                'key': '2108'
            }, _createElement('div', {}, this.pageType !== 'search' && !!this.vehicleString ? [_createElement('div', { 'key': '21941' }, '\n      There\'re no products for \'', this.vehicleString, '` in this ', this.pageType, '. Click\n      ', _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver
                }, 'here'), ' to clear your vehicle\n      criteria and view all products.\n    ')] : null, this.pageType === 'search' && !!this.vehicleString ? [
                '\n    We are displaying search results for \'',
                this.vehicleString,
                '\' and we\'re unable to find products that match\n    "',
                this.query,
                '". Click ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '25611'
                }, 'here'),
                ' to clear\n    your vehicle criteria or try another product search.\n  '
            ] : null, !this.vehicleString ? [
                '\n    We\'re unable to find products that match "',
                this.query,
                '". Click\n    ',
                _createElement('a', {
                    'tabIndex': '0',
                    'className': 'cm_message-link',
                    'onClick': this.startOver,
                    'key': '29721'
                }, 'here'),
                ' to clear selection and browse\n    all parts.\n  '
            ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                'className': 'cmTemplate_timeout',
                'key': '3250'
            }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                'className': 'cmTemplate_partial',
                'key': '3465'
            }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                'className': 'cmTemplate_unknown',
                'key': '3694'
            }, 'We failed to process your request', this.error ? [
                '\n  (',
                this.error.status ? [
                    _createElement('b', { 'key': '38400' }, 'status:'),
                    ' ',
                    this.error.status,
                    ', '
                ] : null,
                this.error.userId ? [
                    _createElement('b', { 'key': '39270' }, 'id:'),
                    ' ',
                    this.error.userId,
                    ', '
                ] : null,
                this.error.timestamp ? [
                    _createElement('b', { 'key': '40100' }, 'timestamp:'),
                    ' ',
                    this.error.timestamp,
                    ', '
                ] : null,
                _createElement('b', { 'key': '38054' }, 'instance:'),
                ' ',
                this.error.instance,
                ')'
            ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                'tabIndex': '0',
                'className': 'cm_message-link',
                'onClick': this.retry
            }, 'try your search again'), '.\n') : null);
        }, {
            widgetName: 'undefined',
            items: undefined
        })], [this.SearchHeader(function () {
            return _createElement('div', { 'className': 'cm_SearchHeader' }, [this.facetDialogButton(function () {
                    return _createElement('div', { 'className': 'cm_facetDialogButton' }, [this.dialogButton(function () {
                            return _createElement('div', { 'className': 'button btn btn-primary cm_dialogButton' }, '\n  Filters \n    ', this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '-5 0 394 394.00003',
                                    'key': '680'
                                }, _createElement('path', { 'd': 'm367.82 0h-351.26c-6.1992-0.011719-11.879 3.4492-14.711 8.9609-2.8711 5.5859-2.3672 12.312 1.3008 17.414l128.69 181.29c0.042968 0.0625 0.089843 0.12109 0.13281 0.18359 4.6758 6.3125 7.207 13.961 7.2188 21.816v147.8c-0.027344 4.375 1.6914 8.582 4.7734 11.688 3.0859 3.1016 7.2812 4.8516 11.656 4.8516 2.2227-0.003906 4.4258-0.44531 6.4805-1.3008l72.312-27.57c6.4766-1.9805 10.777-8.0938 10.777-15.453v-120.02c0.011719-7.8555 2.543-15.504 7.2148-21.816 0.042968-0.0625 0.089844-0.12109 0.13281-0.18359l128.69-181.29c3.668-5.0977 4.1719-11.82 1.3008-17.406-2.8281-5.5156-8.5117-8.9766-14.707-8.9648z' }))] : null, !this.withSelection ? [_createElement('svg', {
                                    'className': 'cm_icon cm_filter-icon',
                                    'viewBox': '0 0 247.46 247.46',
                                    'key': '8090'
                                }, _createElement('path', { 'd': 'm246.74 13.984c-1.238-2.626-3.881-4.301-6.784-4.301h-232.46c-2.903 0-5.545 1.675-6.784 4.301-1.238 2.626-0.85 5.73 0.997 7.97l89.361 108.38v99.94c0 2.595 1.341 5.005 3.545 6.373 1.208 0.749 2.579 1.127 3.955 1.127 1.137 0 2.278-0.259 3.33-0.78l50.208-24.885c2.551-1.264 4.165-3.863 4.169-6.71l0.098-75.062 89.366-108.39c1.848-2.239 2.237-5.344 0.999-7.969zm-103.65 108.89c-1.105 1.34-1.711 3.023-1.713 4.761l-0.096 73.103-35.213 17.453v-90.546c0-1.741-0.605-3.428-1.713-4.771l-80.958-98.191h200.65l-80.958 98.191z' }))] : null);
                        }, {
                            widgetName: 'undefined',
                            items: undefined
                        })]);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], _createElement('div', {
                'className': 'cm_total-hits',
                'role': 'status'
            }, _createElement('span', {}, this.totalHits, ' results')), _createElement('div', { 'className': 'cm_sort sort-by' }, _createElement('span', { 'className': 'sort-label' }, 'Sort by: '), '\n  ', this.sortSelect, '\n'));
        }, {
            widgetName: 'search-header',
            items: undefined
        })], [this.SearchResult(function () {
            function repeatItems1(items, itemsIndex) {
                return [items(function () {
                        return _createElement('div', {
                            'className': 'product-item',
                            'data-catalogid': this.CatalogID,
                            'data-ajaxcart': '1',
                            'data-addcart-callback': 'addcart_callback'
                        }, _createElement('div', { 'className': 'pro-content-holder clearfix' }, _createElement('div', { 'className': 'img' }, _createElement('a', { 'href': this.ProductLink }, _createElement('img', {
                            'src': this.imageOrDefault(this.ThumbnailFile),
                            'alt': this.removeHTML(this.Name),
                            'className': 'img-responsive',
                            'onError': this.onImageError
                        })), _createElement('button', {
                            'className': 'quickview',
                            'data-toggle': 'modal',
                            'onClick': window.Convermax.quickview(this)
                        }, '\n        Quick View\n      ')), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'name' }, _createElement('a', Object.assign({}, { 'href': this.ProductLink }, { dangerouslySetInnerHTML: { __html: this.Name } }))), _createElement('div', { 'className': 'short-description' }, this.ShortDescription), _createElement('div', { 'className': 'price' }, this.OnSale ? _createElement('del', {
                            'className': 'regular-price',
                            'key': '799'
                        }, this.formatPrice(this.RegularPrice)) : null, _createElement('span', { 'className': this.OnSale ? 'sale-price' : 'regular-price' }, this.formatPrice(this.Price))), _createElement('div', { 'className': 'action' }, _createElement('a', {
                            'href': 'add_cart.asp?quick=1&item_id=' + this.CatalogID,
                            'className': 'add-to-cart btn btn-default',
                            'onClick': window.Convermax.addToCart
                        }, _createElement('span', {
                            'className': 'buyitlink-text',
                            'aria-label': 'add to cart'
                        }, 'Add To Cart'), _createElement('span', { 'className': 'ajaxcart-loader icon-spin2 animate-spin' }), _createElement('span', { 'className': 'ajaxcart-added icon-ok' }))))));
                    }, { count: undefined })];
            }
            return _createElement('div', { 'className': 'cm_SearchResult' }, _createElement.apply(this, [
                'div',
                { 'className': 'product-items product-items-3' + (this.view ? ` ${ this.view }` : '') + ' cmRepeater_items' },
                _map(this.items, repeatItems1.bind(this))
            ]), [this.message(function () {
                    return _createElement('div', { 'className': 'cm_message' }, this.hasMessage('TrialExpired') ? [_createElement('div', { 'key': '240' }, _createElement('h2', { 'className': 'cm_message-header' }, 'YMM Search trial period has expired'), '\n  Product information including pricing and fitments is outdated. This store runs on a limited trial version\n  of Convermax Year Make Model fitment search. Please contact\n  ', _createElement('a', {
                            'href': 'mailto:support@convermax.com',
                            'className': 'cm_message-link'
                        }, 'support@convermax.com'), ' to complete setup\n  or cancel the integration.\n')] : null, this.hasMessage('IncompatibleParts') ? [_createElement('div', {
                            'className': 'cm_message__incompatible',
                            'key': '4950'
                        }, _createElement('div', {}, '\n    These parts will not fit your ', _createElement('span', {}, this.vehicleString), '.', _createElement('br', {}), '\n    Parts shown below are based on keyword search only.\n  '))] : null, this.isNoVehicleSpecificResults && this.isVehicleSelected ? [_createElement('div', {
                            'className': 'cm_message__universal',
                            'key': '7690'
                        }, '\n  No vehicle specific products found for\n  ', _createElement('span', {}, '\'', this.vehicleString, '\'.'), _createElement('br', {}), '\n  Press ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'href': this.searchByVehicleUrl
                        }, 'here'), ' to view parts that\n  fit this vehicle.\n')] : null, this.template === 'badRequest' ? _createElement('div', {
                        'className': 'cmTemplate_badRequest',
                        'key': '1136'
                    }, 'We failed to process your request (', _createElement('b', {}, 'status:'), ' ', this.error.status, ', ', _createElement('b', {}, 'id:'), ' ', this.error.userId, ',\n', _createElement('b', {}, 'timestamp:'), ' ', this.error.timestamp, ', ', _createElement('b', {}, 'instance:'), ' ', this.error.instance, ').\n', _createElement('br', {}), '\nYou may ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.startOver
                    }, 'clear your search'), ' and try to\nsearch for something else.\n') : null, this.template === 'corrected' ? _createElement('div', {
                        'className': 'cmTemplate_corrected',
                        'key': '1554'
                    }, 'Your search for ', _createElement('span', { 'className': 'query' }, this.originalQuery), '\ndid not match any of the products we carry. We searched for\n', _createElement('span', { 'className': 'query' }, this.query), ' instead.\n') : null, this.template === 'networkError' ? _createElement('div', {
                        'className': 'cmTemplate_networkError',
                        'key': '1816'
                    }, 'Network error\n', this.error ? [
                        '(id: ',
                        this.error.userId,
                        ', timestamp: ',
                        this.error.timestamp,
                        ', instance: ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nWe could not reach the search server.\n') : null, this.template === 'nothing' ? _createElement('div', {
                        'className': 'cmTemplate_nothing',
                        'key': '2108'
                    }, _createElement('div', {}, this.pageType !== 'search' && !!this.vehicleString ? [_createElement('div', { 'key': '21941' }, '\n      There\'re no products for \'', this.vehicleString, '` in this ', this.pageType, '. Click\n      ', _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver
                        }, 'here'), ' to clear your vehicle\n      criteria and view all products.\n    ')] : null, this.pageType === 'search' && !!this.vehicleString ? [
                        '\n    We are displaying search results for \'',
                        this.vehicleString,
                        '\' and we\'re unable to find products that match\n    "',
                        this.query,
                        '". Click ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '25611'
                        }, 'here'),
                        ' to clear\n    your vehicle criteria or try another product search.\n  '
                    ] : null, !this.vehicleString ? [
                        '\n    We\'re unable to find products that match "',
                        this.query,
                        '". Click\n    ',
                        _createElement('a', {
                            'tabIndex': '0',
                            'className': 'cm_message-link',
                            'onClick': this.startOver,
                            'key': '29721'
                        }, 'here'),
                        ' to clear selection and browse\n    all parts.\n  '
                    ] : null)) : null, this.template === 'timeout' ? _createElement('div', {
                        'className': 'cmTemplate_timeout',
                        'key': '3250'
                    }, 'The request took too long.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null, this.template === 'partial' ? _createElement('div', {
                        'className': 'cmTemplate_partial',
                        'key': '3465'
                    }, 'We were unable to find products that match entire query ', _createElement('span', { 'className': 'query' }, this.originalQuery), '. We\nperformed a partial search instead.\n') : null, this.template === 'unknown' ? _createElement('div', {
                        'className': 'cmTemplate_unknown',
                        'key': '3694'
                    }, 'We failed to process your request', this.error ? [
                        '\n  (',
                        this.error.status ? [
                            _createElement('b', { 'key': '38400' }, 'status:'),
                            ' ',
                            this.error.status,
                            ', '
                        ] : null,
                        this.error.userId ? [
                            _createElement('b', { 'key': '39270' }, 'id:'),
                            ' ',
                            this.error.userId,
                            ', '
                        ] : null,
                        this.error.timestamp ? [
                            _createElement('b', { 'key': '40100' }, 'timestamp:'),
                            ' ',
                            this.error.timestamp,
                            ', '
                        ] : null,
                        _createElement('b', { 'key': '38054' }, 'instance:'),
                        ' ',
                        this.error.instance,
                        ')'
                    ] : null, '.\n', _createElement('br', {}), '\nPlease ', _createElement('a', {
                        'tabIndex': '0',
                        'className': 'cm_message-link',
                        'onClick': this.retry
                    }, 'try your search again'), '.\n') : null);
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })], [this.loadMore(function () {
                    return _createElement('div', { 'className': 'cm_loadMore' }, _createElement('div', { 'className': 'cm_load-more' }, this.isNotAllItemsReceived ? [
                        this.isActive ? [this.loading] : null,
                        !this.isActive ? [[this.loadMoreButton(function () {
                                    return _createElement('div', {
                                        'className': 'btn btn-primary button btn btn-primary cm_loadMoreButton',
                                        'onClick': this.activate
                                    }, '\n        ', window.Convermax.config?.loadMoreText || `Next ${ this.nextPageSize }`, '\n      ');
                                }, {
                                    widgetName: 'undefined',
                                    items: undefined
                                })]] : null
                    ] : null));
                }, {
                    widgetName: 'undefined',
                    items: undefined
                })]);
        }, {
            widgetName: 'results',
            items: undefined
        })])));
}
        export const componentNames = ["cm:filterChips","cm:filterInput","cm:inputMin","cm:inputMax","cm:Inputs","cm:filterInput","cm:select","cm:facets","cm:FacetPanel","cm:message","cm:dialogButton","cm:facetDialogButton","cm:SearchHeader","cm:message","cm:loadMoreButton","cm:loadMore","cm:SearchResult"]