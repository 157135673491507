
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', {
                    'className': 'product-item',
                    'data-catalogid': this.CatalogID,
                    'data-ajaxcart': '1',
                    'data-addcart-callback': 'addcart_callback'
                }, _createElement('div', { 'className': 'pro-content-holder clearfix' }, _createElement('div', { 'className': 'img' }, _createElement('a', { 'href': this.ProductLink }, _createElement('img', {
                    'src': this.imageOrDefault(this.ThumbnailFile),
                    'alt': this.removeHTML(this.Name),
                    'className': 'img-responsive',
                    'onError': this.onImageError
                })), _createElement('button', {
                    'className': 'quickview',
                    'data-toggle': 'modal',
                    'onClick': window.Convermax.quickview(this)
                }, '\n        Quick View\n      ')), _createElement('div', { 'className': 'product-content' }, _createElement('div', { 'className': 'name' }, _createElement('a', Object.assign({}, { 'href': this.ProductLink }, { dangerouslySetInnerHTML: { __html: this.Name } }))), _createElement('div', { 'className': 'short-description' }, this.ShortDescription), _createElement('div', { 'className': 'price' }, this.OnSale ? _createElement('del', {
                    'className': 'regular-price',
                    'key': '799'
                }, this.formatPrice(this.RegularPrice)) : null, _createElement('span', { 'className': this.OnSale ? 'sale-price' : 'regular-price' }, this.formatPrice(this.Price))), _createElement('div', { 'className': 'action' }, _createElement('a', {
                    'href': 'add_cart.asp?quick=1&item_id=' + this.CatalogID,
                    'className': 'add-to-cart btn btn-default',
                    'onClick': window.Convermax.addToCart
                }, _createElement('span', {
                    'className': 'buyitlink-text',
                    'aria-label': 'add to cart'
                }, 'Add To Cart'), _createElement('span', { 'className': 'ajaxcart-loader icon-spin2 animate-spin' }), _createElement('span', { 'className': 'ajaxcart-added icon-ok' }))))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'cm_SearchResult product-recommendations__items' }, _createElement('section', { 'className': 'page_header' }, _createElement('h1', { 'className': 'page_heading' }, 'You May Also Like')), _createElement.apply(this, [
        'div',
        { 'className': 'product-items product-items-3 cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []